import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Box, Container, useMediaQuery, useTheme, Breadcrumbs, Typography, Link } from "@material-ui/core";
import { Image, ListItem } from "../../components";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import Pagination from "@material-ui/lab/Pagination";
import { useData } from "../../hooks/useData";
import { useParams, useLocation } from "react-router-dom";
import { getCategoryById, getParentById } from "../../db/services";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { IconButton } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { changeFav } from "../../store/slices/favoriteSlice";
import { useHistory } from "react-router-dom";

import categoryList from "../../data/category-strcture";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#f7f7f7",
        minHeight: `calc(100vh - 120px)`,
        padding: theme.spacing(5, 0, 10, 0),
    },

    image: {
        width: "70%",
        borderRadius: 1000,

        [theme.breakpoints.down("sm")]: {
            width: 100,
            marginRight: theme.spacing(5),
        },
    },

    categoryContainer: {
        padding: theme.spacing(3),
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 10,

        [theme.breakpoints.down("sm")]: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            height: 100,
            alignItems: "center",
        },
    },
}));

export default function Category() {
    let history = useHistory();
    const classes = useStyles();
    const params = useParams();
    const theme = useTheme();

    const categoryId = params.id;

    const [categoryDetails, setCategoryDetails] = useState(null);
    const [breadCrumb, setBreadCrumb] = useState([]);
    const { offlineMode } = useSelector((state) => state.download);
    const { playing } = useSelector((state) => state.player);

    const matches = useMediaQuery(theme.breakpoints.down("xs"));

    const { loading, totalPages, currentPage, audioList, changePage } = useData({
        offlineMode,
        categoryId,
    });

    const handleChangePage = (_, page) => {
        changePage(page);
    };

    //Get parent key according to id
    let path = [];
    let complete = [];
    const getParentAndGrandParent = (path, json, value) => {
        for (var key in json) {
            if (typeof json[key] === 'object') {
                path.push(json[key].name);
                getParentAndGrandParent(path, json[key], value);
                path.pop();
            } else {
                if (json[key] === value) {
                    for(var i = 0; i < path.length; i++)
                        if(path[i]!= undefined)
                            complete.push(path[i]);
                    // console.log(complete);
                    setBreadCrumb([...complete])
                }
            }
        }
    }

    useEffect(() => {
        const categoryDetails = getCategoryById(categoryId);
        getParentAndGrandParent(path, categoryList, categoryId);
        setCategoryDetails(categoryDetails);
    }, [categoryId]);

    const showPagination = !loading && audioList.length > 0 && totalPages > 1;

    useEffect(() => {
        window?.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [currentPage]);

    const dispatch = useDispatch();
    const { favorite } = useSelector((state) => state.favorite);
    const [present, setPresent] = useState(false);

    function handleFavorite() {
        // present?setPresent(false):setPresent(true);
        // console.log("this is the category data***********:",categoryDetails)
        dispatch(
            changeFav({
                name: categoryDetails.name,
                link: "category-link",
                id: categoryDetails.id,
                image: categoryDetails.image,
                categoryId: categoryDetails.id,
            })
        )
    }
    useEffect(() => {
        if (favorite.find((item) => item.id === categoryDetails?.id)) {
            setPresent(true);
        } else {
            setPresent(false);
        }
    }, [audioList, favorite, categoryDetails]);

    return (
        <div style={playing ? { paddingBottom: 150 } : { paddingBottom: 50 }} className={classes.root}>
            <Container maxWidth="md">
                <Breadcrumbs aria-label="breadcrumb">
                    {breadCrumb.map((item) =>
                        <Link color="inherit" onClick={() => { history.push("/item") }} href="#">
                            {item}
                        </Link>
                    )}
                </Breadcrumbs>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Paper variant="outlined" className={classes.categoryContainer}>
                            <Image className={classes.image} src={categoryDetails?.image} alt="cover_image" />
                            <Box
                                textAlign="center"
                                className={classes.title}
                                my={3}
                                fontSize="h6.fontSize"
                                fontWeight="fontWeightBold"
                            >
                                {categoryDetails?.name}
                            </Box>
                            <IconButton onClick={handleFavorite} size="small">
                                <FavoriteBorderIcon
                                    style={
                                        present ? { color: "rgb(240,100,100)" } : { color: "#777" }
                                    }
                                />
                            </IconButton>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        {audioList.map((item) => {
                            return <ListItem currentPlayingPosition="category" key={item.id} data={item} />;
                        })}
                        {showPagination && (
                            <Box py={2} display="flex" justifyContent="flex-end">
                                <Pagination
                                    onChange={handleChangePage}
                                    size={matches ? "small" : "large"}
                                    page={currentPage}
                                    count={totalPages}
                                    variant="outlined"
                                    shape="rounded"
                                />
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}